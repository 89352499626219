import type { UseFetchOptions } from 'nuxt/app'
import { useRequestHeaders } from 'nuxt/app'
import {useApiConfig} from "~/composables/useApiConfig";

export function useApiFetch<T> (path: string | (() => string), options: UseFetchOptions<T> = {}) {
  let headers: any = {}
  const { apiBaseURL } = useApiConfig();

  const token = useCookie('XSRF-TOKEN');

  if (token.value) {
    headers['X-XSRF-TOKEN'] = token.value as string;

    if (process.server){
      headers = {
        ...headers,
        ...useRequestHeaders(["referer", "cookie"]),
      }
    }
  }
  console.log(apiBaseURL + path)

  return useFetch(apiBaseURL + path, {
    credentials: "include",
    watch: false,
    ...options,
    headers: {
      ...headers,
      ...options?.headers
    }
  });
}
