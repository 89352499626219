export const useApiConfig = () => {
    const config = useRuntimeConfig();
    const apiBaseURL = config.public.apiBaseURL;

    const getImageUrl = (imagePath) => {
        return `${apiBaseURL}/storage/images/${imagePath}`;
    };

    return {
        apiBaseURL,
        getImageUrl
    };
};
