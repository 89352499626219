import { default as aboutCczjGAPqhWMeta } from "/home/forge/myra-calendars.com/pages/about.vue?macro=true";
import { default as auth_45onlyfqSHwp5xNvMeta } from "/home/forge/myra-calendars.com/pages/auth-only.vue?macro=true";
import { default as calendar_45form5oHuzpZ2zRMeta } from "/home/forge/myra-calendars.com/pages/calendar-form.vue?macro=true";
import { default as calendar_45list_45adminpXSHsA35nwMeta } from "/home/forge/myra-calendars.com/pages/calendar-list-admin.vue?macro=true";
import { default as calendar_45listepZ9UASyCgMeta } from "/home/forge/myra-calendars.com/pages/calendar-list.vue?macro=true";
import { default as edit7JTtIWSRYBMeta } from "/home/forge/myra-calendars.com/pages/calendars/[id]/edit.vue?macro=true";
import { default as show0chrpdB0QfMeta } from "/home/forge/myra-calendars.com/pages/calendars/[id]/show.vue?macro=true";
import { default as contactF8hRWahQFxMeta } from "/home/forge/myra-calendars.com/pages/contact.vue?macro=true";
import { default as guest_45onlyboKtxQBzkUMeta } from "/home/forge/myra-calendars.com/pages/guest-only.vue?macro=true";
import { default as indexVRfSD45dUYMeta } from "/home/forge/myra-calendars.com/pages/index.vue?macro=true";
import { default as logina6JQOUJC8mMeta } from "/home/forge/myra-calendars.com/pages/login.vue?macro=true";
import { default as PartnersxktfZ2reCjMeta } from "/home/forge/myra-calendars.com/pages/Partners.vue?macro=true";
import { default as callbackXG9SLjBq4bMeta } from "/home/forge/myra-calendars.com/pages/patreon/callback.vue?macro=true";
import { default as privacy_45policyqmWzvsFKHkMeta } from "/home/forge/myra-calendars.com/pages/privacy-policy.vue?macro=true";
import { default as registeriBi9qlkM8oMeta } from "/home/forge/myra-calendars.com/pages/register.vue?macro=true";
import { default as special_45calendars2cFsbzl3CKMeta } from "/home/forge/myra-calendars.com/pages/special-calendars.vue?macro=true";
import { default as subscribev3jGpOCK2PMeta } from "/home/forge/myra-calendars.com/pages/subscribe.vue?macro=true";
import { default as tosA4pXqMFpuRMeta } from "/home/forge/myra-calendars.com/pages/tos.vue?macro=true";
export default [
  {
    name: aboutCczjGAPqhWMeta?.name ?? "about",
    path: aboutCczjGAPqhWMeta?.path ?? "/about",
    meta: aboutCczjGAPqhWMeta || {},
    alias: aboutCczjGAPqhWMeta?.alias || [],
    redirect: aboutCczjGAPqhWMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: auth_45onlyfqSHwp5xNvMeta?.name ?? "auth-only",
    path: auth_45onlyfqSHwp5xNvMeta?.path ?? "/auth-only",
    meta: auth_45onlyfqSHwp5xNvMeta || {},
    alias: auth_45onlyfqSHwp5xNvMeta?.alias || [],
    redirect: auth_45onlyfqSHwp5xNvMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/auth-only.vue").then(m => m.default || m)
  },
  {
    name: calendar_45form5oHuzpZ2zRMeta?.name ?? "calendar-form",
    path: calendar_45form5oHuzpZ2zRMeta?.path ?? "/calendar-form",
    meta: calendar_45form5oHuzpZ2zRMeta || {},
    alias: calendar_45form5oHuzpZ2zRMeta?.alias || [],
    redirect: calendar_45form5oHuzpZ2zRMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/calendar-form.vue").then(m => m.default || m)
  },
  {
    name: calendar_45list_45adminpXSHsA35nwMeta?.name ?? "calendar-list-admin",
    path: calendar_45list_45adminpXSHsA35nwMeta?.path ?? "/calendar-list-admin",
    meta: calendar_45list_45adminpXSHsA35nwMeta || {},
    alias: calendar_45list_45adminpXSHsA35nwMeta?.alias || [],
    redirect: calendar_45list_45adminpXSHsA35nwMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/calendar-list-admin.vue").then(m => m.default || m)
  },
  {
    name: calendar_45listepZ9UASyCgMeta?.name ?? "calendar-list",
    path: calendar_45listepZ9UASyCgMeta?.path ?? "/calendar-list",
    meta: calendar_45listepZ9UASyCgMeta || {},
    alias: calendar_45listepZ9UASyCgMeta?.alias || [],
    redirect: calendar_45listepZ9UASyCgMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/calendar-list.vue").then(m => m.default || m)
  },
  {
    name: edit7JTtIWSRYBMeta?.name ?? "calendars-id-edit",
    path: edit7JTtIWSRYBMeta?.path ?? "/calendars/:id()/edit",
    meta: edit7JTtIWSRYBMeta || {},
    alias: edit7JTtIWSRYBMeta?.alias || [],
    redirect: edit7JTtIWSRYBMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/calendars/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: show0chrpdB0QfMeta?.name ?? "calendars-id-show",
    path: show0chrpdB0QfMeta?.path ?? "/calendars/:id()/show",
    meta: show0chrpdB0QfMeta || {},
    alias: show0chrpdB0QfMeta?.alias || [],
    redirect: show0chrpdB0QfMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/calendars/[id]/show.vue").then(m => m.default || m)
  },
  {
    name: contactF8hRWahQFxMeta?.name ?? "contact",
    path: contactF8hRWahQFxMeta?.path ?? "/contact",
    meta: contactF8hRWahQFxMeta || {},
    alias: contactF8hRWahQFxMeta?.alias || [],
    redirect: contactF8hRWahQFxMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: guest_45onlyboKtxQBzkUMeta?.name ?? "guest-only",
    path: guest_45onlyboKtxQBzkUMeta?.path ?? "/guest-only",
    meta: guest_45onlyboKtxQBzkUMeta || {},
    alias: guest_45onlyboKtxQBzkUMeta?.alias || [],
    redirect: guest_45onlyboKtxQBzkUMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/guest-only.vue").then(m => m.default || m)
  },
  {
    name: indexVRfSD45dUYMeta?.name ?? "index",
    path: indexVRfSD45dUYMeta?.path ?? "/",
    meta: indexVRfSD45dUYMeta || {},
    alias: indexVRfSD45dUYMeta?.alias || [],
    redirect: indexVRfSD45dUYMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logina6JQOUJC8mMeta?.name ?? "login",
    path: logina6JQOUJC8mMeta?.path ?? "/login",
    meta: logina6JQOUJC8mMeta || {},
    alias: logina6JQOUJC8mMeta?.alias || [],
    redirect: logina6JQOUJC8mMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: PartnersxktfZ2reCjMeta?.name ?? "Partners",
    path: PartnersxktfZ2reCjMeta?.path ?? "/Partners",
    meta: PartnersxktfZ2reCjMeta || {},
    alias: PartnersxktfZ2reCjMeta?.alias || [],
    redirect: PartnersxktfZ2reCjMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/Partners.vue").then(m => m.default || m)
  },
  {
    name: callbackXG9SLjBq4bMeta?.name ?? "patreon-callback",
    path: callbackXG9SLjBq4bMeta?.path ?? "/patreon/callback",
    meta: callbackXG9SLjBq4bMeta || {},
    alias: callbackXG9SLjBq4bMeta?.alias || [],
    redirect: callbackXG9SLjBq4bMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/patreon/callback.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyqmWzvsFKHkMeta?.name ?? "privacy-policy",
    path: privacy_45policyqmWzvsFKHkMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyqmWzvsFKHkMeta || {},
    alias: privacy_45policyqmWzvsFKHkMeta?.alias || [],
    redirect: privacy_45policyqmWzvsFKHkMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registeriBi9qlkM8oMeta?.name ?? "register",
    path: registeriBi9qlkM8oMeta?.path ?? "/register",
    meta: registeriBi9qlkM8oMeta || {},
    alias: registeriBi9qlkM8oMeta?.alias || [],
    redirect: registeriBi9qlkM8oMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/register.vue").then(m => m.default || m)
  },
  {
    name: special_45calendars2cFsbzl3CKMeta?.name ?? "special-calendars",
    path: special_45calendars2cFsbzl3CKMeta?.path ?? "/special-calendars",
    meta: special_45calendars2cFsbzl3CKMeta || {},
    alias: special_45calendars2cFsbzl3CKMeta?.alias || [],
    redirect: special_45calendars2cFsbzl3CKMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/special-calendars.vue").then(m => m.default || m)
  },
  {
    name: subscribev3jGpOCK2PMeta?.name ?? "subscribe",
    path: subscribev3jGpOCK2PMeta?.path ?? "/subscribe",
    meta: subscribev3jGpOCK2PMeta || {},
    alias: subscribev3jGpOCK2PMeta?.alias || [],
    redirect: subscribev3jGpOCK2PMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: tosA4pXqMFpuRMeta?.name ?? "tos",
    path: tosA4pXqMFpuRMeta?.path ?? "/tos",
    meta: tosA4pXqMFpuRMeta || {},
    alias: tosA4pXqMFpuRMeta?.alias || [],
    redirect: tosA4pXqMFpuRMeta?.redirect,
    component: () => import("/home/forge/myra-calendars.com/pages/tos.vue").then(m => m.default || m)
  }
]